@font-face {
  font-family: 'Inter Grab Web';
  src: url("assets/fonts/Inter-Regular.woff2") format("woff2"), url("assets/fonts/Inter-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter Grab Web';
  src: url("assets/fonts/InterTHLooped-Medium.woff2") format("woff2"), url("assets/fonts/InterTHLooped-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter Grab Web';
  src: url("assets/fonts/Inter-Bold.woff2") format("woff2"), url("assets/fonts/Inter-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Grab Community Solid EN';
  src: url("assets/fonts/GrabCommunity/solid-EN/Grab Community Solid EN-Medium.woff2") format("woff2"), url("assets/fonts/GrabCommunity/solid-EN/Grab Community Solid EN-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Grab Community Solid EN';
  src: url("assets/fonts/GrabCommunity/solid-EN/Grab Community Solid EN-Regular.woff2") format("woff2"), url("assets/fonts/GrabCommunity/solid-EN/Grab Community Solid EN-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
body,
html {
  font-family: "Inter Grab Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.noScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.noScrollbar::-webkit-scrollbar {
  display: none;
}
#root > div {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#root > div::-webkit-scrollbar {
  display: none;
}
button {
  -webkit-tap-highlight-color: transparent;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
}
.mapboxgl-map {
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  overflow: hidden;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-align: left;
}
.mapboxgl-map {
  border-radius: .8rem;
}
.mapboxgl-map:-webkit-full-screen {
  width: 100%;
  height: 100%;
}
.mapboxgl-canvas {
  border-radius: 0.8rem;
}
.mapboxgl-ctrl-bottom-left {
  position: absolute;
  pointer-events: none;
  z-index: 2;
}
.mapboxgl-ctrl-bottom-left {
  bottom: 0;
  left: 0;
}
.mapboxgl-ctrl {
  clear: both;
  pointer-events: auto;
  /* workaround for a Safari bug https://github.com/mapbox/mapbox-gl-js/issues/8185 */
  transform: translate(0, 0);
}
.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
  margin: 0 0 10px 10px;
  float: left;
}
@keyframes message-move-in {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes message-move-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
@keyframes message-move-in-from-bottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes message-move-out-from-bottom {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}
@keyframes slideUpIn {
  0% {
    transform: translate(0, 100%);
  }
  100% {
    transform: translate(0, 0);
  }
}
@-webkit-keyframes slideUpIn {
  0% {
    -webkit-transform: translate(0, 100%);
  }
  100% {
    -webkit-transform: translate(0, 0);
  }
}
@keyframes slideUpOut {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 100%);
  }
}
@-webkit-keyframes slideUpOut {
  0% {
    -webkit-transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(0, 100%);
  }
}
@keyframes rcDialogFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rcDialogFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
