// copy from https://gitlab.myteksi.net/engineering/grab-ui/-/merge_requests/117/diffs#78b96d9c45175e92f60c1fe8327d4209e437e7d0
@font-face {
  font-family: 'Inter Grab Web';
  src: url("fonts/Inter-Regular.woff2") format("woff2"),
  url("fonts/Inter-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter Grab Web';
  src: url("fonts/InterTHLooped-Medium.woff2") format("woff2"),
  url("fonts/InterTHLooped-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter Grab Web';
  src: url("fonts/Inter-Bold.woff2") format("woff2"),
  url("fonts/Inter-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

//styleName: Logotype/Logotype – Solid;
@font-face {
  font-family: 'Grab Community Solid EN';
  src: url("fonts/GrabCommunity/solid-EN/Grab Community Solid EN-Medium.woff2") format("woff2"),
  url("fonts/GrabCommunity/solid-EN/Grab Community Solid EN-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Grab Community Solid EN';
  src: url("fonts/GrabCommunity/solid-EN/Grab Community Solid EN-Regular.woff2") format("woff2"),
  url("fonts/GrabCommunity/solid-EN/Grab Community Solid EN-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}